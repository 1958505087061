.logo{ 
    width: 160px;
    height: 55px;
    margin-left: 20px;
}
*{
    box-sizing: border-box;
}
.overlay{
    display: none;
}
.drawer{
    display: none;
}

.insulation_product_features{
    width: 100%;
    min-height: 80vh;
    height: 100%;
    text-align: left;
    padding: 40px;
}
.insulation_product_features > h1{
    color: #1B5174;
}
.insulationMain{
    display: flex;
    flex-wrap: wrap;
}
.insulationMain_firstDiv{
    width: 50%;

}
.insulationMain_secondDiv{
    width: 45%;
    padding-left: 20px;
    align-self: center;
    font-size: 20px;
}
.insulation_panel_products{
    width:100%;
    height: 100%;
    min-height: 50vh;
    padding: 40px;
    background: #f8f9fe;
    text-align: left;
}
.insulation_box_div{
    width: 33.3333%;
    padding-top: 40px;
    text-align: center;
}
/* Dropdown Button */
.dropbtn {
    background-color: white;
    color: black;
    font-size: 16px;
    border: none;
    padding:10px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .sponsor-item{
      cursor: pointer;
  }
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #1B5174;color:#fff}


.menu{
    display:none;
}
.cart{
    width: 30px;
    align-self: top;
    margin-left: 50px;
    cursor: pointer;
    
}
.NavItemCustom{
    font-weight: bolder;
}
.navDiv{
    width: 100%;
    height:80px;
}
.landingUIDiv{
    background: url('../images/hero_banner.png');
    width:100%;
    height:80vh;
    
}
.bannerText{
    color:#fff;
    font-family: Arial;
    font-weight: bolder;
    font-size: 45px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    width: 400px;
    margin-top: 5%;

}
.bannerText >p{
    font-size: 20px;
    font-weight: bold;
}
.ChildDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:40px;
    
}
.demoImage{
    width: 200px;
}

.btn-customize{
    width: 200px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color:#1B5174;
    font-size: 14px;
    border-radius: 20px;
    text-align: center;
    margin-top: 50px;
    cursor: pointer;
}
.demoImageDiv{
    margin-top: 5%;
    display: flex;
    
}
.demoImageDiv > div{
    display: flex;
    flex-direction: column;
    padding-left: 30px;
   
}

.demoImageII{
    height: 150px;
    
}
.demoImageIII{
    height: 150px;
    margin-top: 20px;
}
.NavBarCustom{
    padding:40px
}

.mynav{
    width:100%;
    height:100px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding:20px;
    
}

.navItems{
    display: flex;
    padding:10px
    
}
.navItems > div{
    padding-left:30px;
}

a:link{
    text-decoration: none;
    color:#000;
    font-weight: bold;
}
a:visited{
    color:#000
}
a:hover{
    color:#1B5174
}


.sponsor-container{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
   
    

}
.sponsor-container::-webkit-scrollbar{
    display: none;
}

.sponsor-container > div{
    padding: 40px;
    text-align: center;
    display: flex;
    justify-content: center;

}
.ourproducts{
    width: 100%;
    height: 100vh;
    background: #f1f5f9 ;
    text-align: left;
    
    padding: 40px;
}
.ourproducts >h1{
    color:#1B5174;
}

.containerProducts{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
    margin:auto;
    padding: 20px;
    
    
    
}
.containerProducts > div{
    text-align: left;
}
.categoryImage{
    width: 100%;
    height: 80%;
}
.productTitle{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    background: #fff;
    width: 100%;
    padding-right: 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 20%;
    
}

.title{
    width: 70%;
    font-size: 14px;
    margin-left: 10px;
    padding:10px
}

.pointer_size{
    width: 25px;
    margin-top: 10px;
    cursor: pointer;
    
}

.catergoryDIV{
    
    width: 30%;
    box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.4);
    border-radius: 20px;
    
}

.footer{
    width: 100%;
    height: 50vh;
    background: #000;
    padding:40px;
    color:#fff;
    
}

.mainfootercover{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    margin: 20px;
}
.footer-image{
    width:200px;
}
.social-media{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
   
}
.socialmedia-icon > span{
    padding: 10px;
    cursor: pointer;
}
.footer-tab{
    width: 100%;
    margin-top: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;

}

.footer-tab > div{
    align-self: center;
}
.footer_ourproducts > div{
    margin-bottom: 20px;
}

.fab-banner{
    width: 100%;
    height: 50vh;
    background: url('../images/banner/fabricated_banner.png');
    text-align: left;
    color: #fff;
    padding-left: 30px;
    padding-top: 30vh;
}
.insulation-banner{
    width: 100%;
    height: 50vh;
    background: url('../images/banner/insulation.jpg');
    text-align: left;
    color: #fff;
    padding-left: 30px;
    padding-top: 30vh;
}
.cooling-banner{
    width: 100%;
    height: 50vh;
    background: url('../images/banner/cooling_unit_banner.png');
    text-align: left;
    color: #fff;
    padding-left: 30px;
    padding-top: 30vh;
}
.cooling-details{
    width: 100%;
    height: 50vh;
    background: url('../images/banner/cool-description.png');
    text-align: left;
    color: #fff;
    padding-left: 30px;
    padding-top: 30vh;
}
.description{
    text-align: left;
    display: flex;
    margin-top: 10%;
    justify-content: space-between;
    width: 100%;  
}
.description-details{
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 80%;  
    margin-left: 20px;
    min-height: 50vh;
    padding:20px;
}
.description-details-title{
    padding: 20px;
    text-align: left;

}
.description-details-title > h1{
    font-weight: bolder;
    color: #1B5174;

}
.des-details{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.imageDiv_fab_desc{
    display: flex;    
    padding-left: 30px;
    
}
.small_fab_image{
    width:280px;
}
.small_fab_image_i{
    width:280px;
    margin-top:20px;
}

.divSplit{
    display: flex;
    flex-direction: column;
    
}
.fab_image{
    width: 450px;
    padding-right: 20px;
}
.more_details{

    padding-left: 20px;
    margin-left: 10px;
    font-size: 18px;
    align-self: center;    
}

.more_details > h2{
    color: #1B5174;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 30px;
    
}
.description_list{
    width: 30px;
    margin-right: 10px;
   
}


.features{
    font-weight: bolder;
    font-size: 30px;
    color: #1B5174;
}
.featureDiv{
    background: #d5d5d5;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    padding:30px;
    margin-top: 20px;
    flex-wrap: wrap;
    

}
.list_feature > h1{
    color: #1B5174;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 25px;
   
}
.list_feature >p{
    margin-top: 10px;
}

.list_feature{
    text-align: left;
}
.thumbnail{
    width: 35%;
}

.thumbnail_img{
    width:30%;
    height: 200px;
}
.listImage_child{
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;
}

.P_specifcation{
    display: flex;
    width: 100%;
    height: 80vh;
    text-align: left;
    padding:40px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.DivCard{
    border-radius:10px;
    border:1px solid #d5d5d5;
    box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.4);
    text-align: center;
    padding: 20px;
    width: 23%;
    
}
.containText{
   width:100%;
   margin:10px;
}
.content{
    display: flex;
    justify-content: left;
    padding: 10px;
    font-weight: bolder;
}

.mainDiv{
    padding:30px;
    background:#f1f5f9
}

.mainDiv >h1{
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    color: #1B5174;
    font-size: 25px;
    text-align: left;
    margin-top: 20px;
    
}

.DivCardImage{
    width: 100%;
}

.circular{
    width:30px;
    height: 30px;
    border:1px solid #1B5174;
    border-radius:20px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
}
.viewdetails_btn{
    margin-top: 5px;
    border-radius: 20px;
    background: #000;
    color:#fff;
    font-size: 14px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
    cursor: pointer;
}
.viewmore_btn{
    margin-top: 10px;
    border-radius: 20px;
    background:#1B5174;
    color:#fff;
    font-size: 14px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
    cursor: pointer;
    width: 20%;
    margin:auto;
}
.viewmore_cover{
    width:100%;
    
    
}

.placeholderHeader{
    display: flex;
    justify-content: space-between;
    
} 
.makeOrderCover{
    width: 100%;
    display: flex;
}
.ImageOrder{
    width: 50%;
    background: #000;
}
.productTitle{
    display:flex;
    justify-content: space-between;
    height: 50px;
    background: #f1f5f9;
    
}
.topModal{
    width: 80%;
    height: 70px;
    background: #1B7449;
    font-size: 14px;
    border-radius: 12px;
    padding:10px;
    margin-left: 20px;
    color: #fff;
}

.selectCustom{
    margin-left:20px;
    margin-top:20px;
    width:80%;
}
.selectCustomII{
    display:flex;
    justify-content:space-between;
    width:80%;
    margin-left:20px;
    margin-top:20px;
}
.newDispensation{
    font-weight:bolder;
    font-size:40px;
    font-family:Arial, Helvetica, sans-serif;
}
.leverageClass{
    margin-top:50px
}
.cartMainDiv{
    background:#f1f5f9;
    width:100%;
    min-height:100vh;
    height:100%;

}
.cartui{
    width:70%;
    height: 100%;
    margin: auto;
    margin-top: 30px;
    padding:30px;

}
.cartUI-nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.h1-cart{
    color:#1B5174;
}
.imgCart{
    width:100%
}
.shopping{
    line-height:5;
    cursor:pointer
}

.cancelbtn{
    width:150px;
    height:50px;
    border-radius:25px;
    background:#8C0606;
    cursor:pointer;
    color:#fff;
    text-align: center;
    line-height:3
}

.confirmbtn{
    width:150px;
    height:50px;
    border-radius:25px;
    background:#1B7449;
    cursor:pointer;
    color:#fff;
    text-align: center;
    line-height:3
}
.divbtn{
    display:flex;
    width: 100%;
    text-align: right;
    justify-content: end;
    margin-top:30px;
}
.product_display{
    display:flex;
    justify-content:space-between;
    margin-top:10px
}
.product_display_img{
    width:15%
}
.product_display_name_div{
    width:100%;
    text-align:left;
    
    
}
.list{
    margin-left: 20px ;
}
.product_display_name{
    
    margin-left:20px
}
.close{
    line-height:10;
    margin-right:10px;
    cursor:pointer
}

.desciptionMain{
    padding: 30px;
    min-height: 10vh;
    text-align: left;
}
.desciptionMain_h1{
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    color: #1B5174;
}

.table_des{
    width: 100%;
    height: 100%;
    border: 1px solid #000;
}
.contentDIV{
    width: 50%;
}
@media screen and (max-width: 900px) {
    .description-details{
        justify-content:center;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        
    }
    .contentDIV{
        width: 100%;
        margin: auto;
    }
    
    .containerProducts{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center; 
        
    }
    .product_display_img{
        width: 50%;
    }
    .ourproducts{
        position: relative;
        height: 100%;
        
        margin-bottom: 0px;
    }
    .catergoryDIV{
        width:80%;
        margin-bottom: 20px;
    }
    .navItems {
      display:none;
      
    }
    .menu{
        display: inline;
        line-height: 60px;
        cursor: pointer;
    }
    .bannerText{
        color:#fff;
        font-family: Arial;
        font-weight: bolder;
        font-size: 35px;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        width: 400px;
        margin-top: 3%;
    
    }
    
    .ChildDiv{
        justify-content: center;
    }
    .btn-customize{
        margin-top: 25px;
    }
    
    .demoImageDiv{
        margin-top: 25vh
    }
    
    
    .landingUIDiv{
        height: 55vh;
    }
    
    .footer{
        display: none;
    }
    .newDispensation{
        font-weight:bold;
        font-size:30px;
        font-family:Arial, Helvetica, sans-serif;
    }
    .leverageClass{
        margin-top: 15px;
    }
    .landingUIDiv{
        height: 50vh;
    }
    
    .listImage{
        flex-direction: column;
        width: 80%;
    }
    .featureDiv{
        height: 80vh;
    }
    .P_specification{
        
        justify-content: center;
        align-items: center;
        
    }
    .DivCard{
        width: 70%;
        margin: 10px;
    }
    .description{
        flex-wrap: wrap;
        
        width: 100%;
    }
    .fab_image{
        width: 250px;
        height: 400px;
    }
    .small_fab_image  {
        width: 200px;
    }
    .small_fab_image_i  {
        width: 200px;
    }
    .more_details{
        font-size: 18px;
    }
    .thumbnail_img{
        width:25%;
        height: 150px;
    }
    .listImage_child{
        padding: 20px;
    }
    .more_details{

        padding-left: 20px;
        margin-left: 10px;
        font-size: 18px;
        align-self: center;    
    }
    
    .more_details > h2{
        
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        
    }
    .description_list{
        width: 20px;
        margin-right: 10px;
       
    }
    .title_description{
        font-size: 16px;
    }
    .placeholderImageModal{
        display: none;
    }
    .placeholderDataModal{
        width:100%;
    }
    .overlay{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        z-index: 20;
        justify-content: right;
        align-items: flex-end;
        margin-left:auto;
        margin-right:0;
        overflow-y: hidden;
    }
    .drawer{
        display: block;
        width: 50%;
        height: 100%;
        background-color: #fff;
        margin-left:auto;
        margin-right:0;
        
    }
    .mobile-navItem{
        width:100%;
        background:#1B5174;
        padding:20px;
        color:#fff;
        
    }
    .space{
        margin-top: 10px;
    }
    .mobile-navItem >ul{
       
        list-style-type: none;
        text-align: center;
        padding-left: 0pt;
        
    }
    .mobile-navItem >ul>li{
       
       padding: 10px;
       cursor: pointer;
       padding-left: 0pt;
        
    }
  }